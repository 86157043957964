import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import axios from "axios";
import About from "./about";
import Login from "./base/login";
import SignUp from "./base/signup";
import ForgetPassword from "./base/forgetPassword";
import Home from "./base/home";
import CommonSkeletion from "./base/commonSkeleton";
import ErrorPage from "./base/errorPage";
import { toaster } from "evergreen-ui";
import Privacy from "./base/privacy";
import Terms from "./base/terms";
import Price from "./base/price";
const { firebaseAuth } = require("./base/firebaseTool");

// 请求的拦截器
axios.defaults.baseURL = "/";
axios.interceptors.request.use(
  function (config) {
    const email = localStorage.getItem("email");
    const uid = localStorage.getItem("uid");

    if (config.method === "post") {
      config.data = {
        email: email,
        uid: uid,
        ...config.data,
      };
    } else if (config.method === "get") {
      config.params = {
        ...config.params,
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    // console.log(response);
    // console.log(response["data"]["status"]);
    // get response status code
    let status = response["data"]["status"];
    if (status === 201) {
      toaster.danger(response["data"]["msg"], { duration: 2 });
    } else if (status === 202) {
      // jump home
      window.location.href = "/";
    } else if (status === 203) {
      // uid error / illegal uid
      toaster.closeAll();
      // remove all items from local storage
      localStorage.clear();
      window.location.href = "/";
    }
    return response.data;
  },
  function (error) {
    if (error.response.status >= 500) {
      toaster.warning("Server Error~", { duration: 2 });
    }

    return Promise.reject(error);
  }
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <CommonSkeletion />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "signup",
    element: <SignUp />,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/price",
    element: <Price />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <RouterProvider router={router} />
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
