import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toaster } from "evergreen-ui";
import HomeLogo from "../media/homelogo128.png";
import ChromeLogo from "../media/chrome.png";

export default function Navbar(props) {
  // 汉堡包
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const [email, setEmail] = useState(null);
  useEffect(() => {
    console.log("navbar load");
    const token = localStorage.getItem("token");
    if (token === null) {
      setEmail("");
      return;
    }
  }, []);

  let UserIcon = () => {
    return (
      <React.Fragment>
        <div className="navbar-item has-dropdown is-hoverable">
          <a
            className="navbar-link"
            style={{
              fontWeight: "700",
              lineHeight: "1.1",
            }}
          >
            {email}
          </a>
          <div className="navbar-dropdown">
            <Link
              to="/dashboard"
              className="navbar-item"
              onClick={() => {
                setIsBurgerActive(false);
              }}
            >
              Dashboard
            </Link>
            <hr className="navbar-divider"></hr>
            <Link
              to="/"
              className="navbar-item"
              onClick={() => {
                setIsBurgerActive(false);
                localStorage.removeItem("token");
                setEmail("");
              }}
            >
              Logout
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <nav
        className="navbar is-fixed-top has-background-white is-spaced"
        role="navigation"
        aria-label="main navigation"
        style={{ height: 80 }}
      >
        <div className="container">
          <div className="navbar-brand" style={{ marginTop: "0px" }}>
            {/* <Link className="" to="/">
              <img src={HomeLogo} width="45" height="45"></img>
            </Link> */}

            <a
              onClick={() => {
                setIsBurgerActive(!isBurgerActive);
              }}
              role="button"
              className={`navbar-burger burger ${
                isBurgerActive ? "is-active" : ""
              }`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div
            className={`navbar-menu ${isBurgerActive ? "is-active" : ""}`}
            id="navbarBasicExample"
          >
            <div className="navbar-start">
              <div>
                <Link
                  to="/"
                  className="has-text-primary"
                  style={{
                    fontSize: "20px",
                    fontWeight: "900",
                    marginLeft: "4px",
                    marginTop: "14px",
                  }}
                >
                  ProfileToSheet
                </Link>
                {/* <Link
                  to="/"
                  className="has-text-warning"
                  style={{
                    fontSize: "20px",
                    fontWeight: "900",
                  }}
                >
                  me
                </Link> */}
              </div>
            </div>
            <div className="navbar-end">
              <div className="navbar-item">
                <Link to="/price">
                  <strong>Pricing</strong>
                </Link>
              </div>
              <Link
                to="https://chrome.google.com/webstore/detail/profile2sheet/kppepaimbbebioicpndogipphgjdhcmk"
                className="button"
                onClick={() => {}}
              >
                <span className="icon">
                  <img src={ChromeLogo} width="18" height="18"></img>
                </span>
                <span
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Install for free
                </span>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
}
