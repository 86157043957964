import React from "react";
import { Link } from "react-router-dom";
import HeroIcon from "../media/hero-feature.png";
import ChromeLogo from "../media/chrome.png";

export default function FeatureProductivity(props) {
  return (
    <React.Fragment>
      <div className="container mt-6"></div>
      <div className="columns is-vcentered">
        <div
          className="column is-5"
          style={{
            fontSize: "60px",
            fontWeight: "900",
            lineHeight: "1.1",
            // marginTop: "auto",
          }}
        >
          <p className="has-text-info">Enhance </p>
          <p className="has-text-success">Your Networking</p>
          <p className="has-text-info">and</p>
          <p className="has-text-success-dark">Career Growth</p>
          <div className="subtitle is-5 mt-1">
            Efficiently manage LinkedIn information and unlock valuable
            networking opportunities. This tool supports your professional
            development by fostering meaningful connections.
          </div>
        </div>
        <div className="column is-7 has-text-centered">
          <img src={HeroIcon} alt="hero" />
        </div>
      </div>
    </React.Fragment>
  );
}
