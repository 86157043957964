import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function Terms(props) {
  return (
    <React.Fragment>
      <div className="columns is-centered">
        <div className="column is-8">
          <Link to="/" className="button is-primary is-rounded mt-6">
            <ArrowBackIosIcon />
            Back to home
          </Link>
          <h1 className="title has-text-centered mt-6">Term of service</h1>

          <h2 className="subtitle is-3">Introduction</h2>
          <p className="content">
            This document is a legal agreement between you and us i.e
            “ProfileToSheet“. It governs your use of the online properties and,
            in any case, the use of the services provided. “Legal agreement”
            means that the terms of this agreement are binding on the
            relationship between you and us once you have accepted the terms.
          </p>
          <p className="content">
            Please read these Terms of Service carefully before using the
            https://profiletosheet.com website operated by ​our parent company
            Yedap Technologies, LLC. Your access to and use of the Service is
            conditioned on your acceptance of and compliance with these Terms.
            These Terms apply to all visitors, users, and others who access or
            use the Service.
          </p>
          <p className="content">
            By accessing or using the Service you agree to be bound by these
            Terms. If you don’t agree with these terms you cannot access the
            services provided by us.
          </p>

          <h2 className="subtitle is-3">Account Terms</h2>
          <p className="content">
            You must be 16 years or older to use this Service. If you are
            seeking to download voice content to the site or utilize other
            services within the site, you may be required to sign up for an
            account. Users must register in a truthful and complete manner by
            providing all the required data in the relevant registration form.
            Clients are in charge of keeping their login certifications private.
            It is comprehended that the Owner will not be considered dependable
            under any conditions if there should be an occurrence of misfortune,
            exposure, theft, or unapproved use by outsiders. You should be
            human. Records enrolled by “bots” or other computerized strategies
            are not allowed.
          </p>

          <h2 className="subtitle is-3">Content Disclaimer</h2>
          <p className="content">
            We don’t own any content ownership as our work is to get the data
            from the user and distribute it to other platforms. The only content
            we own on this website is all in the form of text, graphics, user
            interfaces, visual interfaces, photographs, trademarks, logos,
            sounds, artwork, computer code & our AI Voices that’s it!
            ProfileToSheet is the only passive uninvolved channel for the
            distribution of such data and isn’t attempted any commitment or risk
            identifying with the substance or the client’s activities As we
            don’t own any content we’re not responsible for any offensive
            content on this site submitted by the user but surely we’ll take
            down that content if it violates our terms of services.
          </p>
          <h2 className="subtitle is-3">Termination</h2>
          <p className="content">
            We may terminate or suspend access to our Service immediately,
            without prior notice or liability, for any reason whatsoever,
            including without limitation if you breach the Terms. All provisions
            of the Terms which by their nature should survive termination shall
            survive termination, including, without limitation, ownership
            provisions, warranty disclaimers, indemnity, and limitations of
            liability Reasons we have the right to terminate any account without
            any notice are as follows:
          </p>
          <p className="content">
            The user has violated our Agreement; User’s access or use of the
            Service may result in injury to the Owner, other Users, or third
            parties; In case of an investigation by legal action or governmental
            involvement; The account is deemed to be, at the Owner’s sole
            discretion, for whatever reason, inappropriate or offensive, or in
            violation of this Agreement. If you do not follow these simple
            guidelines we reserve the right to delete your account without
            warning.
          </p>
          <h2 className="subtitle is-3">Payment and Refund</h2>
          <p className="content">
            If you are not satisfied with our platform, you can contact us
            within 5 days and use under 10,000 text characters since your first
            payment. We will process it within one business day. No hard
            feelings, no questions asked. The inability to pay for services
            conveyed will result in the suspension of service and additionally,
            late installment expense’s as per the Late Payment of Commercial
            Debt Act (1998). If services are being left unpaid for an
            all-inclusive time frame we maintain all authority to expel the
            service (counting all information) from our servers without notice.
            If you have any problem with our Payment and Refund policies, please
            feel free to contact us.
          </p>
          <h2 className="subtitle is-3">General Support terms</h2>
          <p className="content">
            Support is provided through emails for your queries. We aim to
            respond to support queries within 24 hours of submission. We often
            respond much sooner than this, however, our standard products do not
            include any form of support time response guarantee unless otherwise
            specified. Corporate customers who require a dedicated account
            manager or guaranteed response times should contact us to arrange a
            premium service level agreement. Using abusive language and cuss
            words with our support team will not be tolerated.
          </p>
          <h2 className="subtitle is-3">Honesty Policy</h2>
          <p className="content">
            <div className="content">
              <ul>
                <li>
                  There is no copyrighted content permitted on the
                  ProfileToSheet, We own all rights to reproduce AI Voices and
                  we pass full copyright to our paying customers.
                </li>
                <li>
                  You may not post content unless you created it or participated
                  closely in its creation. “I have permission” does not mean you
                  created it.
                </li>
                <li>
                  You may not post content that is unlawful, harmful,
                  threatening, abusive, harassing, tortuous, defamatory, vulgar,
                  obscene, libelous, invasive of another’s privacy, hateful, or
                  racially, ethnically, or otherwise objectionable.
                </li>
                <li>
                  You may not post content that is destructive to minors in any
                  capacity.
                </li>
                <li>
                  You may not request different clients of the ProfileToSheet
                  Site to join any other web services or other administration.
                </li>
                <li>
                  You may not upload, post, email, or otherwise transmit any
                  unsolicited or unauthorized advertising, promotional
                  materials, “junk mail,” “spam,” “chain letters,” “pyramid
                  schemes,” or any other form of solicitation.
                </li>
              </ul>
            </div>
          </p>
          <h2 className="subtitle is-3">Warranty and Disclaimer</h2>
          <p className="content">
            We do not make any commitments about the content within the
            Services, the specific functions of the Services, or their
            reliability, availability, or ability to meet your needs. We provide
            the Services “as is”.
          </p>
          <p>
            We do not promise that the site or any content, service, or feature
            of this site will be error-free or uninterrupted, or that use of
            this site will provide specific results.
          </p>
          <p>
            Any Misfortune or Harm of any sort acquired Because of the
            utilization of the “ProfileToSheet” services The owner does not
            underwrite, warranty, certification, or Accept Accountability for
            any occasion, Products, or services accessible through the
            “Services” or any site Available through the services.
          </p>
          <h2 className="subtitle is-3">Change in the Agreement</h2>
          <p className="content">
            We have the right to change the agreement process when we need to do
            it by sending a notice to you before we make the changes to the
            agreement.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
