import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/app-check";

const config = {
  apiKey: "AIzaSyB7BiP1FAccZJ3-DMI5p3fBskpWZVq5VoQ",
  authDomain: "testapiproject-379907.firebaseapp.com",
  projectId: "testapiproject-379907",
  storageBucket: "testapiproject-379907.appspot.com",
  messagingSenderId: "3011866646",
  appId: "1:3011866646:web:5ae809494f93a4e53c8537",
  measurementId: "G-H9WMRZCEK7",
};

firebase.initializeApp(config);

const appCheck = firebase.appCheck();
appCheck.activate("6LdzzmIlAAAAAHis_n5TXc2N0po-tt8yEKORcLP0");
const firebaseAuth = firebase.auth();

export { firebaseAuth };
